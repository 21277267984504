<template>
  <div class="main-box">
    <s-header :name="'设置'"></s-header>
    <van-cell-group style="margin: 10px">
      <van-cell title="密码" center>
        <template #right-icon>
          <span class="font-style" style="margin-right: 6px">******</span>
          <!-- <van-icon :name="showPassword1?'eye':'closed-eye'" size="20" @click="showPassword1 = !showPassword1"/> -->
          <span @click="show=true" class="font-style">修改</span>
        </template>
      </van-cell>
    </van-cell-group>
    <van-cell-group style="margin: 10px">
      <van-cell title="意见反馈" to="feedback"/>
    </van-cell-group>
    <van-button class="outBtn" @click="outLogin">退出登录</van-button>
    <van-popup
        v-model:show="show"
        closeable
        position="bottom"
        :style="{ height: '30%' }"
        @close="closePopup"
        :close-on-click-overlay="false"
    >
      <div style="margin: 20px">
        <span style="font-size: 18px;">修改密码</span>
        <van-field v-model="newPWd" placeholder="请输入新密码" style="margin: 20px 0" :border="false" center
                   :type="showPassword?'':'password'">
          <template #button>
            <van-icon :name="showPassword?'eye':'closed-eye'" size="30" @click="showPassword = !showPassword"/>
          </template>
        </van-field>
        <van-button type="success" size="large" @click="getUpdatePwd">确定</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import sHeader from '@/components/SimpleHeader'
import {deleteLocal} from '@/common/js/utils'
import {onMounted, reactive, toRefs} from "vue";
import {showFailToast,showSuccessToast} from "vant";
import {changePassword} from '@/service/user'
import {getMemberInfo} from "../../../service/user";
import router from "../../../router";

export default {
  name: "SetUp",
  components: {
    sHeader
  },
  setup() {
    const state = reactive({
      show: false,
      newPWd: '',
      showPassword: false,
      showPassword1: false,
      oldPassWord: ''
    })

    onMounted(async () => {
      await getMemberInfoFun()
    })
    //关闭弹窗
    const getMemberInfoFun = async () => {
      const memberInfo = await getMemberInfo()
      state.oldPassWord = memberInfo.data.password
    }
    //关闭弹窗
    const closePopup = () => {
      state.newPWd = ''
    }
    //提交新密码
    const getUpdatePwd = async () => {
      if (state.newPWd === '') {
        showFailToast('请输入新的密码！')
      }
      let param = {
        password: state.newPWd
      }
      await changePassword(param)
      state.oldPassWord = state.newPWd
      state.newPWd = ''
      showSuccessToast('修改成功！')
      state.show = false
      await getMemberInfoFun()
    }
    function outLogin() {
      deleteLocal('token')
      router.go(0)
    }
    return {
      ...toRefs(state),
      outLogin,
      closePopup,
      getUpdatePwd,
      getMemberInfoFun
    }
  }
}
</script>

<style scoped>
.main-box {
  background: #F9F9F9;
}

.font-style {
  color: #a0a3a7;
  font-size: 14px;
}

/deep/ .van-field__control {
  border: 1px solid #c4c5c6;
  padding-left: 10px;
}
  .outBtn{
    width: 92%;
    margin: 20px 4%;
    color: #8C211B;
  }
</style>
